// import React, { useRef } from "react";
import {
  FooterLayout,
  ValuesLayout,
  VisionsLayout,
  OurCompanyLayout,
  SpesialisedLayout,
  AddedValueLayout,
  BenefitLayout,
  // ContactUsLayout,
} from "../../layouts";
import Styles from "./Styles.module.scss";
import NavProvider from "../../Context/NavContext";
import BannerLayout from "../../layouts/BannerLayout";
import CompanyDiverseSection from "../../layouts/CompanyDiverse";
import { WA_LOGO } from "../../assets/png";
// import CarrerSection from "../../layouts/Career";
// import NewsLayout from "../../layouts/News";
// import VideoLayout from "../../layouts/Video";

export default function Main() {
  // function openTab() {
  //   window.open(
  //     "https://api.whatsapp.com/send?phone=628159200022&text=Halo%21%20Admin%20mau%20Tanya%20Layanan%20Outsourcing%20Permata%20Indonesia."
  //   );
  // }

  return (
    <NavProvider>
      <div className={Styles.Container}>
        <div className={Styles.banner}>
          <BannerLayout />
        </div>

        <ValuesLayout />
        <VisionsLayout />
        <CompanyDiverseSection />
        <OurCompanyLayout />
        <SpesialisedLayout />
        <AddedValueLayout />
        <BenefitLayout />
        {/* <CarrerSection /> */}
        {/* <ContactUsLayout /> */}
        <a
          href="https://api.whatsapp.com/send?phone=628159200022"
          className={Styles.tesWa}
        >
          <div className={Styles.LogoWrapper}>
            <img src={WA_LOGO} alt="WA_LOGO" />
          </div>
          <span>Contact Us</span>
        </a>
        <FooterLayout />
      </div>
    </NavProvider>
  );
}
